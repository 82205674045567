var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'themes_cards',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[(_vm.block.block_title === null)?_c('h2',[_vm._v("\n\t\t\t\t\t\tНаши темы\n\t\t\t\t\t")]):_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"themes_cards_block"},[_c('div',{staticClass:"row"},_vm._l((_vm.block.items),function(item,index){return _c('div',{key:index,staticClass:"col-12 col-md-6 col-lg-3 mb-4"},[_c('div',{class:'theme ' + item.theme},[_c('div',{staticClass:"icon"},[_c('div',{class:item.icon})]),_vm._v(" "),(item.description)?_c('div',{staticClass:"description order-1",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e(),_vm._v(" "),(item.title)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t")]):_vm._e()])])}),0)]),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('dynamic-button',{attrs:{"button":_vm.block.button,"block-id":_vm.block.id,"scenario":_vm.block.scenario}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }