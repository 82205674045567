<template>
	<div
		:class="['panel', 'themes_cards',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null">
							Наши темы
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>

			<div class="themes_cards_block">
				<div class="row">
					<div
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-3 mb-4"
					>
						<div :class="'theme ' + item.theme">
							<div class="icon">
								<div :class="item.icon" />
							</div>
							<div v-if="item.description" class="description order-1" v-html="item.description" />
							<div v-if="item.title" class="title">
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="isFooterVisible" class="panel_footer">
				<dynamic-button
					:button="block.button"
					:block-id="block.id"
					:scenario="block.scenario"
				/>
				<!--<panel-footer v-else :block="block"/>-->
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'PanelCenterCards',
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-themes-cards";
</style>
